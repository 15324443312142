import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from 'apollo-link';
//import EchoLink from "./echo-link";
import WsEchoLink from "./ws-echo-link";
import Pusher from "pusher-js";
import Echo from "laravel-echo";

import cache from "./cache";

import { resolvers, typeDefs } from "./resolvers";
import INITIAL_DATA from "./initial-data";


// const httpLink = createUploadLink({ uri: 'https://api.your-crowd.com/graphql' });
const httpLink = createUploadLink({ uri: 'https://api.diamond-star-club.me/graphql' });

const siteUri = 'api.diamond-star-club.me';

const authLink = setContext((_, { headers }) => {

    const authToken = localStorage.getItem('authToken');

    if(!authToken){
        return { headers: {...headers } }
    }

    return {
        headers: {
            ...headers,
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
        }
    }
});

if (window.Pusher) {
    window.Pusher = Pusher;
}

if (!window.Echo) {
    const token = localStorage.getItem('authToken');

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'be83c327f9006b62dace',
        cluster: 'eu',
        authEndpoint: `https://${siteUri}/graphql/subscriptions/auth`,
        wsHost: siteUri,
        wsPort: 6001,
        wssPort: 6001,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        auth: {
            headers: {
                Authorization: token ? `Bearer ${token}` : null,
            },
        },
    });
}

const wsLink = new WsEchoLink();

const client = new ApolloClient({
    link: ApolloLink.from([
        wsLink, authLink.concat(httpLink)
    ]),
    cache,
    typeDefs,
    resolvers
});

client.writeData({ data : INITIAL_DATA });

export default client;